<template>
  <tr class="creator-step-item" :passed="passed">
    <td bold>{{ label }}</td>
    <td>{{ value }}</td>
    <td><iconic :name="`${passed ? 'mf_check' : 'mf_times'}`" /></td>
  </tr>
</template>

<script>
export default {
  props: ["label", "value", "passed"],
};
</script>
